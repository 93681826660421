import classNames from "classnames";
import React from "react";
import styles from "components/Card/card.module.scss";

interface IProps {
  className?: string;
  removeStyleOnMobile?: boolean;
  children: React.ReactNode;
}

export const Card: React.FC<IProps> = (props) => {
  const { className, removeStyleOnMobile, children, ...rest } = props;
  return (
    <div
      className={classNames(className, {
        [styles.card]: true,
        [`${styles.removeOnMobile}`]: removeStyleOnMobile,
      })}
      {...rest}
    >
      {children}
    </div>
  );
};
