import { GetStaticProps, GetStaticPropsResult } from "next";
import React from "react";
import {
  DEFAULT_FOOTER_BLOCK_ID,
  fetchFooterBlock,
} from "api/prismic/blocks/footerBlock";
import {
  DEFAULT_HEADER_BLOCK_ID,
  fetchHeaderBlock,
} from "api/prismic/blocks/headerBlock";
import { fetchGlobal } from "api/prismic/global";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { Footer } from "components/Footer/Footer";
import { Header } from "components/Header/Header";
import { I18nDictionary } from "components/I18nContextProvider/I18nContextProvider";
import { fetchI18nDictionary } from "helpers/fetchI18nDictionary";
import { parseLocale } from "helpers/parseLocale";
import { defaultLocale } from "helpers/url";
import { HTTPStatusCode, IFooter, IHeader } from "interfaces/interfaces";
import { IAppProps } from "pages/_app";

interface IProps extends IAppProps {
  header: IHeader | null;
  footer: IFooter | null;
}

const Custom400 = ({ header, footer }: IProps) => {
  const headerElement = header && <Header header={header} />;
  const footerElement = footer && <Footer block={footer} />;
  return (
    <ErrorPage
      header={headerElement}
      footer={footerElement}
      statusCode={HTTPStatusCode.NOT_FOUND}
    />
  );
};

export const getStaticProps: GetStaticProps<IProps> = async ({
  locale,
}): Promise<GetStaticPropsResult<IProps>> => {
  const { language, locale: podplayLocale } = parseLocale(
    locale || defaultLocale
  );
  const i18nDictionaryResponse = await fetchI18nDictionary(language);

  const [headerResponse, footerResponse, globalResponse] = await Promise.all([
    fetchHeaderBlock(podplayLocale, DEFAULT_HEADER_BLOCK_ID),
    fetchFooterBlock(podplayLocale, DEFAULT_FOOTER_BLOCK_ID),
    fetchGlobal(podplayLocale),
  ]);

  const i18nDictionary = i18nDictionaryResponse.ok
    ? i18nDictionaryResponse.body
    : ({} as I18nDictionary);
  const header = headerResponse.ok ? headerResponse.body : null;
  const footer = footerResponse.ok ? footerResponse.body : null;
  const global = globalResponse.ok ? globalResponse.body : null;

  return {
    props: {
      i18nDictionary,
      header,
      footer,
      global,
    },
  };
};

export default Custom400;
