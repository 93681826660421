import { I18nKey } from "locales/constants/I18nKey";
import NextHead from "next/head";
import React, { ReactElement } from "react";
import { ButtonLink } from "components/Button/ButtonLink";
import { Card } from "components/Card/Card";
import { useI18n } from "hooks/useI18n";
import { HTTPStatusCode } from "interfaces/interfaces";
import styles from "./errorPage.module.scss";

export interface IProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  statusCode?: HTTPStatusCode;
}

export const ErrorPage = (props: IProps): ReactElement => {
  const { statusCode } = props;
  const { i18n } = useI18n();
  const isPageNotFound = statusCode === HTTPStatusCode.NOT_FOUND;
  return (
    <>
      <NextHead>
        <title>
          {i18n(
            isPageNotFound
              ? I18nKey.SEO_ERROR_PAGE_404_TITLE
              : I18nKey.SEO_ERROR_PAGE_COMMON_TITLE
          )}
        </title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </NextHead>
      <div className={styles.errorPage}>
        {props.header}
        <main
          data-testid={isPageNotFound ? "error-404" : "error-common"}
          className={styles.errorPageContainer}
        >
          <div className={styles.errorPageRow}>
            <div className={styles.errorPageCol}>
              <Card removeStyleOnMobile className={styles.textCenter}>
                <h1>
                  {isPageNotFound
                    ? i18n(I18nKey.ERROR_PAGE_404_HEADING)
                    : i18n(I18nKey.ERROR_PAGE_COMMON_HEADING)}
                </h1>
                <p className="text-black-60">
                  {isPageNotFound
                    ? i18n(I18nKey.ERROR_PAGE_404_BODY)
                    : i18n(I18nKey.ERROR_PAGE_COMMON_BODY)}
                </p>
                {statusCode && (
                  <p className={styles.statusCode}>
                    {/* a11y finds code color contrast too similar to the background color */}
                    <code data-ignore-a11y="color-contrast">
                      {i18n(I18nKey.ERROR_PAGE_STATUS_CODE, {
                        status: statusCode.toString(10),
                      })}
                    </code>
                  </p>
                )}
                <ButtonLink
                  primary
                  className={styles.buttonMargin}
                  href={isPageNotFound ? "/" : ""}
                  data-testid="error-button"
                >
                  {isPageNotFound
                    ? i18n(I18nKey.ERROR_PAGE_404_BUTTON)
                    : i18n(I18nKey.ERROR_PAGE_COMMON_BUTTON)}
                </ButtonLink>
              </Card>
            </div>
          </div>
        </main>
        {props.footer}
      </div>
    </>
  );
};
